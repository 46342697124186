/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'prismjs/themes/prism-tomorrow.css'
import './src/assets/sass/main.scss'
import { wrapWithProvider } from './src/wrapWithProvider'

export const wrapRootElement = wrapWithProvider
