module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.swarmia.com","noQueryString":true,"noHash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WWLSQCV","includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-autolink-headers","id":"697ca539-ae59-56a1-bbda-5a071af88c8e","name":"gatsby-remark-autolink-headers","version":"5.25.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"enableCustomId":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-images","id":"0c461bb7-ca01-5568-be29-e3076221957c","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1000,"srcSetBreakpoints":[320,480,640,720,1280,1440,2000],"showCaptions":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-external-links","id":"ba0435ef-9b07-5c28-a01a-374dc78a0d64","name":"gatsby-remark-external-links","version":"0.0.4","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-external-links/index.js","pluginOptions":{"plugins":[],"target":"_blank","rel":"noopener noreferrer"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-prismjs","id":"5023d14b-37aa-5851-92fb-c14b1e804e35","name":"gatsby-remark-prismjs","version":"6.25.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"noInlineHighlight":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"enableCustomId":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"srcSetBreakpoints":[320,480,640,720,1280,1440,2000],"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/website-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ff7ec55b25d2de3571b19e85ebfdff45"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ba566b9f5a504e4aa2b6d637ba9678cd@o337318.ingest.sentry.io/5191389","environment":"production","enabled":true},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
